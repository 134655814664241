<template>
  <div class="scence-select-container">
    <div class="scence-img scence-img-active">
      <img src="@/assets/thumb-cover.jpeg" alt="">
      <div class="scence-img-title ellipsis">sdsjkfjdkfjdkfjkd</div>
      <div class="scence-img-more-container">
        <scence-more></scence-more>
      </div>
    </div>
    <div class="scence-img">
      <img src="@/assets/thumb-cover.jpeg" alt="">
      <div class="scence-img-title ellipsis">sdsjkfjdkfjdkfjkd</div>
      <div class="scence-img-more-container">
        <scence-more></scence-more>
      </div>
    </div>
    <div class="scence-add">
      <i class="iconfont">&#xe602;</i>
      添加场景
    </div>
  </div>
</template>
<script>
import ScenceMore from './components/scence-more'
export default {
  components:{
    ScenceMore
  }
}
</script>
<style lang="scss">
  .scence-select-container{
    height: 130px;
    background: #333333;
    border-radius: 4px;
    padding: 0 10px;
    display: flex;
    align-items: flex-end;
    .scence-img{
      width: 60px;
      height: 80px;
      margin-right: 20px;
      position: relative;
      cursor: pointer;
      .scence-more-container{
        position: absolute;
        top: 40px;
        right: 5px;
        height: 14px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #616161;
        color: #ffffff;
        cursor: pointer;
        border-radius: 2px;
      }
      img{
        width:100%;
      box-shadow: 3px 3px 9px rgba(0,0,0,.5);
      }
      .scence-img-title{
        width: 60px;
        height: 60px;
        line-height: 20px;
        font-size: $font-size-small;
        color: #999999;
      }
    }
    .scence-img-active{
      img{
      box-sizing: border-box;
      border: 2px solid #f5e557;
      }
    }
    .scence-add{
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: $font-size-small;
      border: 1px dotted #777777;
      border-radius: 5px;
      cursor: pointer;
      .iconfont{
        font-size: $font-size-large-x;
      }
    }
    .scence-add:hover{
      color: #eeeeee;
      border-color: #eeeeee;
    }
  }
</style>