<template>
  <div class="pano-start-tip-container">
    <div class="pano-start-tip-title">{{ name }}</div>
    <div class="pano-start-tip-preview-container">
        <img :src="url" alt="">
    </div>
    <div class="pano-start-tip-preview-reselect-container">
        <div class="reselect-tip-container">
            <div class="reselect-tip">建议尺寸</div>
            <div class="reselect-tip">300x300</div>
        </div>
        <pano-button name="选择图片"></pano-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    url:{
      type: String,
      default: "",
    }
  },
};
</script>
<style lang="scss">
.pano-start-tip-container {
  border-top: 1px solid #4b4b4b;
  border-bottom: 1px solid #222222;
  padding: 40px 15px;
  color: #ffffff;
  .pano-start-tip-title {
    font-size: $font-size-small;
    margin-bottom: 10px;
  }
  .pano-start-tip-preview-container{
      width: 250px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #4b4b4b;
      border-radius: 5px;
      img{
          width: 60%;
      }
      margin-bottom: 10px;
  }
  .pano-start-tip-preview-reselect-container{
      display: flex;
      justify-content: space-between;
      color: #999999;
  }
}
</style>