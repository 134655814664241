<template>
  <div class="camera-cointainer">
      <div class="camera-top"></div>
      <div class="camera-right"></div>
      <div class="camera-bottom"></div>
      <div class="camera-left"></div>
      <div class="camera-left-top"></div>
      <div class="camera-top-left"></div>
      <div class="camera-top-right"></div>
      <div class="camera-right-top"></div>
      <div class="camera-right-bottom"></div>
      <div class="camera-bottom-right"></div>
      <div class="camera-bottom-left"></div>
      <div class="camera-left-bottom"></div>
      <div class="camera-set-button">
          <pano-button name="把当前视角设为初始视角" @click="setDefaultView()"></pano-button>
      </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
    computed:{

    ...mapState('editModule',['krpano']),
    },
    data(){
        return {
            src1:''
        }
    },
    methods:{
        setDefaultView(){
        }
    }
};
</script>
<style lang="scss">
.camera-cointainer {
  position: absolute;
  left: 50%;
  height: 100%;
  .camera-set-button{
      position: absolute;
      bottom: 25%;
      width: calc(100vh / 4);
      left: calc(50vh / -4);
  }
  .camera-top{
      position: absolute;
      top: 20%;
      width: calc(200vh / 3);
      height: 2px;
      left: calc(100vh / -3);
      border-bottom: 1px dotted #ffffff;
  }
  .camera-right{
      position: absolute;
      top: 20%;
      width: 2;
      height: 60%;
      left: calc(100vh / 3);
      border-right: 1px dotted #ffffff;
  }
  .camera-bottom{
      position: absolute;
      bottom: 20%;
      width: calc(200vh / 3);
      height: 2px;
      left: calc(100vh / -3);
      border-top: 1px dotted #ffffff;
  }
  .camera-left{
      position: absolute;
      top: 20%;
      width: 2;
      height: 60%;
      left: calc(100vh / -3);
      border-left: 1px dotted #ffffff;
  }
  .camera-left-top{
      position: absolute;
      width: 5px;
      height: 60px;
      top: 20%;
      left: calc(100vh / -3 - 2.5px);
      background: #ffffff;
  }
  .camera-top-left{
      position: absolute;
      width: 80px;
      height: 5px;
      top: 20%;
      left: calc(100vh / -3);
      background: #ffffff;
  }
  .camera-top-right{
      position: absolute;
      width: 80px;
      height: 5px;
      top: 20%;
      left: calc(100vh / 3 - 80px);
      background: #ffffff;
  }
  .camera-right-top{
      position: absolute;
      width: 5px;
      height: 60px;
      top: 20%;
      left: calc(100vh / 3 - 2.5px);;
      background: #ffffff;
  }
  .camera-right-bottom{
      position: absolute;
      width: 5px;
      height: 60px;
      bottom: 20%;
      left: calc(100vh / 3 - 2.5px);;
      background: #ffffff;
  }
  .camera-bottom-right{ 
      position: absolute;
      width: 80px;
      height: 5px;
      bottom: 20%;
      left: calc(100vh / 3 - 80px);
      background: #ffffff;
  }
  .camera-bottom-left{
      position: absolute;
      width: 80px;
      height: 5px;
      bottom: 20%;
      left: calc(100vh / -3);
      background: #ffffff;
  }
  .camera-left-bottom{
      position: absolute;
      width: 5px;
      height: 60px;
      bottom: 20%;
      left: calc(100vh / -3  - 2.5px);
      background: #ffffff;
  }
}
</style>