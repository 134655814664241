<template>
    <div class="group-more-container" @click="openPopup($event)">
    <i class="iconfont">&#xe686;</i>
    </div>
</template>
<script>
import GroupPopup from '../group-popup'
export default {
  methods: {
    openPopup(e) {
      this.$popup({ e:e, template: GroupPopup });
    },
  },
}
</script>
<style lang="scss">
    // .group-more-container{

    // }
</style>