<template>
    <div class="base-screen-container">
        <pano-info></pano-info>
        <pano-global></pano-global>
        <pano-global-switch></pano-global-switch>
    </div>
</template>
<script>
import PanoInfo from './pano-info'
import PanoGlobal from './pano-global'
import PanoGlobalSwitch from './pano-global-switch'
export default {
    components:{
        PanoInfo,
        PanoGlobal,
        PanoGlobalSwitch
    }
}
</script>
<style lang="scss">
    .edit-screen-container{
    color: #ffffff;
    width: calc(100vw - 300px);
        height: calc(100vh - 220px);
        border-color: #4b4b4b #000000 #000000 #4b4b4b;
        border-width: 1px;
        border-style: solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        box-sizing: border-box;
        overflow: auto;
    }
</style>