<template>
    <div class="slide-complete-container">
        <pano-button name="完成" size="medium" @click="openNotify()"></pano-button>
    </div>
</template>
<script>
export default {
    methods:{
        openNotify(){
            this.$notify({});
        }
    }
}
</script>
<style lang="scss">
    .slide-complete-container{
        border-top: 1px solid #4b4b4b;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        .pano-button-container{
            width: 120px;
        }
    }
</style>