<template>
  <div class="scence-more-container" @click="openPopup($event)">
    <i class="iconfont">&#xe686;</i>
  </div>
</template>
<script>
import ScencePopup from "../scence-popup";
export default {
  methods: {
    openPopup(e) {
      this.$popup({ e, template: ScencePopup });
    },
  },
};
</script>
<style lang="scss">
.scence-more-container {
}
</style>