<template>
  <div class="edit-container">
    <NavBar></NavBar>
    <div class="edit-body-container">
      <NavLeft></NavLeft>
      <div class="edit-body-middle-container">
        <div class="edit-dynamic-screen-container">
          <!-- 基础主屏信息 -->
          <EditScreen v-show="!showPanoScreen"></EditScreen>
          <!-- 全局主屏信息 -->
          <EditPanoScreen v-show="showPanoScreen"></EditPanoScreen>
        </div>
        <ScenceBar></ScenceBar>
      </div>
      <EditProps></EditProps>
    </div>
    <transition name="slide-right">
      <nav-right-slide v-if="showSlide"></nav-right-slide>
    </transition>
  </div>
</template>
<script>
import NavBar from "./components/nav-bar";
import NavLeft from "./components/nav-left";
import EditScreen from "./components/edit-screen";
import EditPanoScreen from "./components/edit-pano-screen"
import EditProps from "./components/edit-props";
import ScenceBar from "./components/scence-bar";
import NavRightSlide from "./components/nav-right-slide";
import { mapState } from 'vuex';
export default {
  data () {
    return {};
  },
  computed: {
    ...mapState('editModule', ['slide', 'leftNavIndex', 'krpano']),
    showSlide () {
      return this.slide != null;
    },
    showPanoScreen () {
      return this.leftNavIndex > 0;
    }
  },
  mounted () {
    window.updateHotSpotData = this.updateHotSpotData
  },
  beforeDestroy () {
    window.updateHotSpotData = null;
  },
  methods: {
    updateHotSpotData (sceneId, hsId, ath, atv, hotspotType) {
      console.log('updateHotSpotData.sceneId:'+sceneId);
      console.log('updateHotSpotData.hsId:'+hsId);
      console.log('updateHotSpotData.ath:'+ath);
      console.log('updateHotSpotData.atv:'+atv);
      console.log('updateHotSpotData.hotspotType:'+hotspotType);
    }
  },
  components: {
    NavBar,
    NavLeft,
    EditScreen,
    EditProps,
    ScenceBar,
    NavRightSlide,
    EditPanoScreen
  },
};
</script>
<style lang="scss">
.edit-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #333333;
  color: #999999;
  position: relative;
  .edit-body-container {
    display: flex;
    flex: 1;
    .edit-body-middle-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      .edit-dynamic-screen-container {
      }
    }
  }
}
</style>