<template>
  <div class="scence-top-bar-container">
    <div class="scence-select-img">
      <img src="@/assets/scencelist.png" alt="">
    </div>
    <div class="scence-tab-container">
      <scence-tab></scence-tab>
    </div>
    <div class="scence-tab-end-container">
      <div class="scence-add">
        <i class="iconfont">&#xe602;</i>
      </div>
      <div class="scence-hide">
        <i class="iconfont">&#xe64b;</i> 隐藏缩略图
      </div>
    </div>
  </div>
</template>
<script>
import ScenceTab from './scence-tab'
export default {
  components:{
    ScenceTab
  }
}
</script>
<style lang="scss">
  .scence-top-bar-container{
    height: 30px;
    display: flex;
    .scence-select-img{
      margin-right: 8px;
      display: flex;
      align-items: flex-end;
      cursor: pointer;
      img{
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
    }
    .scence-tab-container{
      flex: 1;
    }
    .scence-tab-end-container{
      display: flex;
      align-items: flex-end;
      margin-bottom: 5px;
      .scence-add,.scence-hide{
        cursor: pointer;
        background: #4b4b4b;
        border-radius: 4px;
        font-size: $font-size-small-x;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        transition: all .3s ease-in-out;
      }
      .scence-add{
        margin-right: 10px;
      }
      .scence-hide{
        width: 100px;
        font-weight: 400;
        font-size: $font-size-small;
        .iconfont{
          margin-right: 6px;
        }
      }
      .scence-add:hover,.scence-hide:hover{
        color: #ffffff;
      }
    }
  }
</style>