<template lang="html">
  <div class="edit-pano-screen-container">
    <div id="pano" style="flex: 1;width:100%;height:100%;">
      <noscript>
        <table style="width:100%;height:100%;">
          <tr style="vertical-align:middle;">
            <td>
              <div style="text-align:center;">ERROR:<br /><br />Javascript not activated<br /><br /></div>
            </td>
          </tr>
        </table>
      </noscript>
    </div>
    <camera v-show="leftNavIndex==1"></camera>
  </div>
</template>
<script>
import Camera from './components/camera'
import { mapActions, mapState } from 'vuex';
export default {
  mounted () {
      embedpano({
        xml: "/tour.xml",
        target: "pano",
        html5: "auto",
        mobilescale: 1.0,
        passQueryParameters: true,
        onready:this.onready
      });
  },
  computed:{
    ...mapState('editModule',['leftNavIndex','krpano'])
  },
  data(){
return {
}
  },
  methods:{
    ...mapActions('editModule',['initEdit','hotspotMove']),
    onready(kp){
     setTimeout(() => {
      this.initEdit(kp)
     }, 200);
    },
      // //热点移动
      // moveHotspot() {
      //   if (this.isHotspotMoving) {
      //     this.krpano.call('screentosphere(mouse.x, mouse.y, mouseath, mouseatv);')
      //     this.krpano.set('hotspot[' + this.selectedHotspot.name + '].ath'
      //       , this.krpano.get("mouseath") + this.selectedHotspot.athDis)
      //     this.krpano.set('hotspot[' + this.selectedHotspot.name + '].atv'
      //       , this.krpano.get("mouseatv") + this.selectedHotspot.atvDis)
      //   }
      // }
  },
  components:{
    Camera
  }
}
</script>
<style lang="scss">
.edit-pano-screen-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 300px);
  height: calc(100vh - 220px);
  border-color: #4b4b4b #000000 #000000 #4b4b4b;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  #pano{
  box-shadow: 0px 3px 10px 1px rgba($color: #000000, $alpha: 0.6);
  }
}
</style>