<template>
  <div class="edit-screen-container">
    <base-screen></base-screen>
  </div>
</template>
<script>
import BaseScreen from './components'
export default {
  components: {
    BaseScreen
  }
}
</script>
<style lang="scss">
.edit-screen-container {
  color: #ffffff;
  width: calc(100vw - 300px);
  height: calc(100vh - 220px);
  border-color: #4b4b4b #000000 #000000 #4b4b4b;
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  box-sizing: border-box;
  overflow: auto;
}
</style>