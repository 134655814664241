<template>
  <div class="scence-tab-container">
    <div class="scence-tab-item scence-tab-item-active">
      <div class="scence-tab-name">默认分组</div>
      <group-more></group-more>
    </div>
    <div class="scence-tab-item">
      <div class="scence-tab-name">默认分组1</div>
      <group-more></group-more>
    </div>
    <div class="scence-tab-item">
      <div class="scence-tab-name">默认分组1</div>
      <group-more></group-more>
    </div>
    <div class="scence-tab-item">
      <div class="scence-tab-name">默认分组1</div>
      <group-more></group-more>
    </div>
  </div>
</template>
<script>
import GroupMore from './components/group-more'
export default {
  data(){
    return {
    }
  },
  components:{
    GroupMore
  }
}
</script>
<style lang="scss">
  .scence-tab-container{
      display: flex;
    .scence-tab-item{
      cursor: pointer;
      margin-right: 10px;
      display: flex;
      align-items: center;
      padding: 2px 10px;
      background: #303030;
      border-bottom: 2px solid #2c2c2c;
      font-size: $font-size-small;
      .scence-tab-name{
        margin-right: 10px;
        margin-left: 5px;
      }
      .group-title-more-container{
        font-weight: 800;
        cursor: pointer;
        padding: 5px;
      }
    }
    .scence-tab-item:hover{
      background: #333333;
      border-bottom: 2px solid #333333;
      color: #ffffff;
    }
    .scence-tab-item-active{
      background: #333333;
      border-bottom: 2px solid #333333;
      color: #ffffff;
    }
  }
</style>