<template>
  <div class="nav-left-container">
    <div class="nav-item-container">
      <div
        v-for="(nav,index) in leftNavs"
        :key="index"
        class="nav-item"
        :class="{'nav-item-active':index==leftNavIndex}"
        @click="navClick(index)"
      >
        <i
          class="iconfont"
          v-html="nav.icon"
        ></i><span>{{nav.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('editModule', ['leftNavs','leftNavIndex'])
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  methods: {
      ...mapActions('editModule',['leftNavClick']),
    navClick (i) {
      this.leftNavClick(i)
    }
  }
}
</script>
<style lang="scss">
.nav-left-container {
  height: 100%;
  width: 50px;
  border-color: #4b4b4b #000000 #000000 #4b4b4b;
  border-width: 1px;
  border-style: solid;
  padding: 10px 0;
  box-sizing: border-box;
  .nav-item-container {
    padding: 10px 0;
    .nav-item {
      padding: 8px 0 8px 0;
      text-align: center;
      cursor: pointer;
      .iconfont {
        font-size: $font-size-medium-x;
        display: block;
      }
      span {
        font-size: $font-size-small;
      }
    }
    .nav-item-active {
      background: $color-theme;
      color: #ffffff;
    }
  }
}
</style>