<template>
  <div class="pano-right-click-container">
    <slide-title name="自定义右键"></slide-title>
    <slide-scroll>
      <div class="pano-right-click-body-container"></div>
    </slide-scroll>
    <slide-complete></slide-complete>
  </div>
</template>
<script>
import SlideTitle from '../slide-title'
import SlideScroll from '../slide-scroll'
import SlideComplete from '../slide-complete'
export default {
  components: {
    SlideTitle,
    SlideScroll,
    SlideComplete
  }
}
</script>
<style lang="scss">
.pano-right-click-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .pano-right-click-body-container {
    flex: 1;
  }
}
</style>