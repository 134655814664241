<template>
  <div class="one-group-more-container">
    <div class="one-group-more-popup-item">重命名</div>
    <div class="one-group-more-popup-item one-group-more-popup-item-del">
      删除
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
<style lang="scss">
.one-group-more-container {
  background: #333333;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid#383838;
  box-shadow: -4px 3px 7px 3px rgba($color: #000000, $alpha: 0.6);

  .one-group-more-popup-item {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px;
  }
  .one-group-more-popup-item-del {
    color: $color-danger;
  }
  .one-group-more-popup-item:hover {
    background: #5c5c5c;
  }
}
</style>