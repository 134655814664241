<template>
    <div class="nav-bar-container">
        <div class="nav-bar-left-container">
            <div class="nav-bar-button"><i class="iconfont">&#xe601;</i>返回</div>
        </div>
        <div class="nav-bar-title-container">{{title}}</div>
        <div class="nav-bar-right-container">
            <div class="nav-bar-button"><i class="iconfont">&#xe645;</i>保存</div> 
            <div class="nav-bar-button"><i class="iconfont">&#xe60f;</i>预览</div>
            </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    computed:{
        ...mapState('editModule',['title'])
    }
}
</script>
<style lang="scss">
    .nav-bar-container{
        display: flex;
        height: 40px;
        min-height: 40px;
        border-bottom:1px solid #000000;
        box-sizing: border-box;
        .nav-bar-left-container,.nav-bar-right-container{
            display: flex;
            align-items: center;
        }
        .nav-bar-left-container{
            padding-left: 10px;
        }
        .nav-bar-right-container{
            justify-content: flex-end;
        }
        .nav-bar-title-container{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
        }
        .nav-bar-button{
            height: 100%;
            padding: 0 5px;
            transition: all .3s ease;
            display: flex;
            align-items: center;
            margin-right: 10px;
            font-size: $font-size-small-x;
            cursor: pointer;
            .iconfont{
                font-weight: 600;
                color: #ffffff;
                margin-right: 5px;
            }
        }
        .nav-bar-button:hover{
            color: #ffffff;
        }
        .nav-bar-button:active{
            background: #131313;
        }
    }
</style>