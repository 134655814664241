<template>
  <div class="pano-info-container">
    <div class="pano-info-project-container">
      <div class="pano-info-project-title">
        <div class="project-title">作品信息</div>
        <div class="project-private">公开作品?</div>
      </div>
      <!-- 作品信息体 -->
      <div class="pano-info-project-body-container">
        <div class="project-cover">
          <img
            class="project-cover-img"
            src="@/assets/thumb-cover.jpeg"
            alt=""
          />
      <div class="project-cover-container">
            <pano-button
            class="project-cover-select"
            name="选择封面"
          ></pano-button>
          <div class="cover-tip">
            <div>建议尺寸</div>
            <div>512 x 512</div>
          </div>
      </div>
        </div>
        <div class="project-input-container">
          <div class="project-input-item-container">
            <pano-edit-input
              class="project-input-item"
              placeholder="x"
            ></pano-edit-input>
            <div class="project-input-limit">0/50</div>
          </div>
          <div class="project-input-item-container">
            <pano-edit-input
              class="project-input-item"
              placeholder="请输入作品标题"
            ></pano-edit-input>
            <div class="project-input-limit">0/50</div>
          </div>
          <div
            class="project-input-item-container project-text-area-item-container"
          >
            <pano-edit-text-area
              class="project-input-item"
              placeholder="请输入作品简介"
            ></pano-edit-text-area>
            <div class="project-input-limit"></div>
          </div>
          <div class="project-input-item-container">
            <pano-button
              class="project-input-item-button"
              name="添加标签"
              @click="openTagSlide()"
            ></pano-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PanoEditInput from "../../../base-components/pano-edit-input";
import PanoEditTextArea from "../../../base-components/pano-edit-text-area";
import PanoTag from "../../../nav-right-slide/components/pano-tag";
const TAGE_SLIDE = { type: PanoTag };
export default {
  components: {
    PanoEditInput,
    PanoEditTextArea,
  },
  computed: {
    ...mapState("editModule", ["slide"]),
  },
  methods: {
    ...mapActions("editModule", ["openSlide"]),
    openTagSlide() {
      if (this.slide == null) {
        this.openSlide(TAGE_SLIDE);
      } else {
        if (!(this.slide.type === TAGE_SLIDE.type)) {
            this.openSlide({...TAGE_SLIDE,reload:true});
        }
      }
    },
  },
};
</script>
<style lang="scss">
.pano-info-container {
  color: #ffffff;
  width: 900px;
  .pano-info-project-container {
    .pano-info-project-title {
      display: flex;
      justify-content: space-between;
      font-size: $font-size-medium;
      margin-bottom: 20px;
    }
    .pano-info-project-body-container {
      display: flex;
      margin-bottom: 40px;
      .project-cover {
        width: 200px;
        height: 200px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.6);
        margin-right: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .project-cover-img {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .project-cover-select {
          display: inline-block;
          width: 80px;
          margin-bottom: 10px;
          z-index: 1;
          margin-right: 10px;
        }
        .project-cover-container{
          display: flex;
          justify-content: center;
          .cover-tip{
            z-index: 1;
            font-size: $font-size-small;
            margin-top: 5px;
          }
        }
      }
      .project-input-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        .project-input-item-container {
          display: flex;
          margin-bottom: 10px;
          .project-input-item {
            display: block;
          }
          .project-input-item-button {
            width: 80px;
          }
          .project-input-limit {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #777777;
          }
        }
        .project-text-area-item-container {
          flex: 1;
          .project-input-item {
            resize: none;
          }
        }
      }
    }
    .pano-info-golbal-setting {
      .pano-golbal-button {
        padding: 0 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        display: inline-block;
      }
    }
  }
}
</style>