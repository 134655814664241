<template>
  <div class="pano-global-container">
      <!-- 全局设置 -->

      <div class="pano-info-project-title">
        <div class="project-title">全局设置</div>
      </div>
      <div class="pano-info-golbal-setting">
        <pano-button v-for="(btn,index) in btns" :key="index" class="pano-golbal-button" :name="btn.name" @click="handleBtnClick(btn.type)"></pano-button>
      </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import PanoStart from '../../../nav-right-slide/components/pano-start'
import PanoCover from '../../../nav-right-slide/components/pano-cover'
import PanoAnim from '../../../nav-right-slide/components/pano-anim'
import PanoInitScence from '../../../nav-right-slide/components/pano-init-scence'
import PanoCustomLogo from '../../../nav-right-slide/components/pano-custom-logo'
import PanoCustomBtn from '../../../nav-right-slide/components/pano-custom-btn'
import PanoCustomAdvert from '../../../nav-right-slide/components/pano-custom-advert'
import PanoUiTemplate from '../../../nav-right-slide/components/pano-ui-template'
import PanoUiLanguage from '../../../nav-right-slide/components/pano-ui-language'
import PanoSecret from '../../../nav-right-slide/components/pano-secret'
import PanoAutoCruise from '../../../nav-right-slide/components/pano-auto-cruise'
import PanoTalk from '../../../nav-right-slide/components/pano-talk'
import PanoGyroscope from '../../../nav-right-slide/components/pano-gyroscope'
import PanoRightClick from '../../../nav-right-slide/components/pano-right-click'
export default {
  data(){
    return {
      btns:[
        {
          name:'开场提示',
          type:PanoStart
        },
        {
          name:'开场封面',
          type:PanoCover
        },
        {
          name:'开场动画',
          type:PanoAnim
        },
        {
          name:'自定义初始场景',
          type:PanoInitScence
        },
        {
          name:'自定义LOGO',
          type:PanoCustomLogo
        },
        {
          name:'自定义按钮',
          type:PanoCustomBtn
        },
        {
          name:'自定义广告',
          type:PanoCustomAdvert
        },
        {
          name:'界面模板',
          type:PanoUiTemplate
        },
        {
          name:'界面语言',
          type:PanoUiLanguage
        },
        {
          name:'访问密码',
          type:PanoSecret
        },
        {
          name:'自动巡游',
          type:PanoAutoCruise
        },
        {
          name:'说一说',
          type:PanoTalk
        },
        {
          name:'手机陀螺仪',
          type:PanoGyroscope
        },
        {
          name:'自定义右键',
          type:PanoRightClick
        }
      ]
    }
  },
  computed:{
    ...mapState('editModule',['slide'])
  }
  ,
  methods:{
    ...mapActions('editModule',['openSlide']),
    handleBtnClick(type){
      let reload=(this.slide||{}).type != type;
      this.openSlide({type,reload:reload});
    }
  }
}
</script>
<style lang="scss">
.pano-global-container{
width: 900px;
          margin-bottom: 20px;
      .pano-info-project-title{
      display: flex;
      justify-content: space-between;
      font-size: $font-size-medium;
      margin-bottom: 20px;
      }
      .pano-info-golbal-setting{
        .pano-golbal-button{
          padding: 0 20px; 
          margin-right: 20px;
          margin-bottom: 20px;
          display: inline-block;
        }
      }
}
</style>