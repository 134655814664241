<template>
    <div class="edit-props-container">
        <component :is="renderProp"></component>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('editModule',['leftNavs','leftNavIndex']),
        renderProp(){
            return this.leftNavs[this.leftNavIndex].prop
        }
    }
}
</script>
<style lang="scss">
    .edit-props-container{
        border-color: #4b4b4b #000000 #000000 #4b4b4b;
        border-style: solid;
        border-width: 1px;
        width: 250px;
        box-sizing: border-box;
        height: calc(100vh - 40px);
    }
</style>