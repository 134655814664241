<template>
<textarea
      class="pano-edit-text-area"
      type="text"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('textarea', $event.target.value)"
    />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.pano-edit-text-area {
  border: 1px solid #4b4b4b;
  border-radius: 2px;
  width: 100%;
  line-height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  background: #222222;
  color: #ffffff;
}
.pano-edit-text-area:hover,.pano-edit-text-area:focus {
  border: 1px solid #89a2b9;
}
</style>
