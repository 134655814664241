<template>
    <div class="nav-right-slide-container">
        <component :is="(slide || {}).type"></component>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('editModule',['slide'])
    }
    
}
</script>
<style lang="scss">
    .nav-right-slide-container{
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        width: 280px;
        background: #333333;
        box-shadow: 5px 5px 8px 4px rgba(0,0,0,.6);
    }
</style>