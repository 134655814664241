<template>
  <div class="pano-tag-container">
    <slide-title name="作品标签"></slide-title>
    <slide-scroll>
        <!-- 系统推荐 -->
      <div class="pano-tag-recommand-container">
        <div class="pano-tag-title">系统推荐</div>
        <div class="recommand-item-cointainer">
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
          <div class="recommand-item">推荐</div>
        </div>
      </div>
      <!-- 自定义标签 -->
      <div class="pano-tag-custom-container">
        <div class="pano-tag-title">自定义标签</div>
        <div class="pano-tag-input-container">
          <pano-edit-input placeholder="自定义标签"></pano-edit-input>
          <pano-button name="创建" size="medium"></pano-button>
        </div>
      </div>
      <!-- 已添加标签 -->
      <div class="pano-tag-exists-container">
        <div class="pano-tag-title">已添加标签</div>
        <div class="pano-tag-already-add-container">
          <div class="tag-already-item">
            推荐<i class="iconfont tag-remove">&#xe60a;</i>
          </div>
          <div class="tag-already-item">
            推荐<i class="iconfont tag-remove">&#xe60a;</i>
          </div>
        </div>
      </div>
    </slide-scroll>

    <slide-complete></slide-complete>
  </div>
</template>
<script>
import SlideTitle from "../slide-title";
import SlideComplete from "../slide-complete";
import SlideScroll from "../slide-scroll";
import PanoEditInput from "../../../base-components/pano-edit-input";
export default {
  methods: {},
  components: {
    SlideTitle,
    PanoEditInput,
    SlideComplete,
    SlideScroll
  },
};
</script>
<style lang="scss">
.pano-tag-container {
  display: flex;
  flex-direction: column;
  height: 100%;
    .pano-tag-recommand-container {
      padding: 20px 15px 10px 15px;
      .recommand-item-cointainer {
        margin-bottom: 20px;
        .recommand-item {
          display: inline-block;
          line-height: 20px;
          padding: 0 12px;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
          background: #777777;
          color: #ffffff;
          font-size: $font-size-small;
        }
      }
    }
    .pano-tag-custom-container {
      padding: 0 15px 40px 15px;
      border-bottom: 1px solid #222222;
      .pano-tag-input-container {
        display: flex;
        justify-content: space-between;
        .pano-edit-input {
          width: 150px;
        }
        .pano-button-container {
          width: 50px;
        }
      }
    }
    .pano-tag-exists-container {
      padding: 20px 15px 10px 15px;
      border-top: 1px solid #4b4b4b;
      display: flex;
      flex-direction: column;
      flex: 1;
      .pano-tag-already-add-container {
        flex: 1;
        .tag-already-item {
          display: inline-block;
          line-height: 20px;
          padding: 0 12px;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
          background: #777777;
          color: #ffffff;
          font-size: $font-size-small;
          cursor: pointer;
          .tag-remove {
            margin-left: 10px;
            font-size: $font-size-small;
          }
        }
      }
    }
  .pano-tag-title {
    color: #ffffff;
    font-size: $font-size-small-x;
    margin-bottom: 20px;
  }
}
</style>