<template>
    <div class="scence-popup-container">
        <div class="scence-popup-item">重命名</div>
        <div class="scence-popup-item scence-popup-item-del">删除</div>
        <div class="scence-popup-item">替换封面</div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss">
    .scence-popup-container{
        background: #333333;
        color: #ffffff;
        border-radius: 5px;
        border: 1px solid#383838;
        box-shadow:-4px 3px 7px 3px rgba($color: #000000, $alpha: .6);
        .scence-popup-item{
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0 20px;
        }
        .scence-popup-item-del{
            color: $color-danger;
        }
        .scence-popup-item:hover{
            background: #5c5c5c;
        }
    }
</style>