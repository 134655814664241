<template>
  <div class="pano-start-container">
    <slide-title name="开场提示"></slide-title>
    <slide-scroll>
      <pano-start-tip name="桌面端" :url="pcUrl"></pano-start-tip>
      <pano-start-tip name="移动端" :url="mobileUrl"></pano-start-tip>
      <div class="pano-start-time-container">显示时间</div>
    </slide-scroll>
    <slide-complete></slide-complete>
  </div>
</template>
<script>
import SlideTitle from "../slide-title";
import SlideComplete from "../slide-complete";
import SlideScroll from "../slide-scroll";
import PanoStartTip from "../pano-start/components/pano-start-tip";
export default {
  data() {
    return {
      pcUrl: "",
      mobileUrl: "",
    };
  },
  created() {
    this.pcUrl = require("@/assets/pc_anim.png");
    this.mobileUrl = require("@/assets/mobile_anim.png");
  },
  components: {
    SlideTitle,
    PanoStartTip,
    SlideComplete,
    SlideScroll,
  },
};
</script>
<style lang="scss">
.pano-start-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .pano-start-tip-container:first-child {
    border-top: none;
  }
  .pano-start-time-container {
    flex: 1;
    border-top: 1px solid #4b4b4b;
  }
}
</style>