<template>
  <div class="pano-global-switch-container">
      
      <!-- 全局开关 -->
      <div class="pano-info-project-title">
        <div class="project-title">全局开关</div>
      </div>
      <div class="pano-info-golbal-setting">
        <pano-switch class="pano-golbal-switch" name="创作者名称" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="浏览量" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="场景选择" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="场景名称" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="分组自动切换" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="简介" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="点赞" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="分享" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="全屏" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="清屏" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="VR眼镜" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="视角切换" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="平台标识" :value="true"></pano-switch>
        <pano-switch class="pano-golbal-switch" name="作品来源" :value="true"></pano-switch>
      </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
.pano-global-switch-container{
width: 900px;
      .pano-info-project-title{
      display: flex;
      justify-content: space-between;
      font-size: $font-size-medium;
      margin-bottom: 20px;
      }
      .pano-info-golbal-setting{
        .pano-golbal-switch{
          margin-right: 30px;
          margin-bottom: 20px;
        }
      }
}
</style>