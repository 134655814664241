<template>
  <div class="scence-bar-container">
    <scence-top-bar></scence-top-bar>
    <scence-select></scence-select>
  </div>
</template>
<script>
import ScenceTopBar from './components/scence-top-bar'
import ScenceSelect from './components/scence-select'
export default {
  components:{
    ScenceTopBar,
    ScenceSelect
  }
}
</script>
<style lang="scss">
  .scence-bar-container{
    height: 180px;
    border-width: 1px;
    border-style: solid;
    border-color: #4b4b4b #000000 #000000 #4b4b4b;
    padding: 8px;
    background: #2c2c2c;
    box-sizing: border-box;
  }
</style>